const getColor = (item: string) => {
    const normalizedItem = item.toLowerCase()

    // Choose a color based on the first letter of the normalized name
    const firstLetter = normalizedItem.charAt(0)

    switch (firstLetter) {
        case `a`:
        case `b`:
        case `c`:
            return `danger`

        case `d`:
        case `e`:
        case `f`:
            return `warning`

        default:
            return `primary`
    }
}

const getBase64 = (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = error => reject(error)
    })
}

export {
    getColor,
    getBase64
}
